import { Fragment,useState } from 'react'

import type { InputNumberProps } from 'antd';
import { Col, InputNumber, Row, Slider, Space } from 'antd';

import { Dialog, DialogPanel,Disclosure,
DisclosureButton,
DisclosurePanel,
Popover,
PopoverButton,
PopoverGroup,
PopoverPanel,
Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon,ArrowPathIcon,
PaperAirplaneIcon,
CheckBadgeIcon,
CodeBracketSquareIcon,
RectangleStackIcon,
ChartPieIcon,
ArrowDownOnSquareStackIcon,
CursorArrowRaysIcon,
FingerPrintIcon,
SquaresPlusIcon,
CloudArrowUpIcon,
LockClosedIcon,
ServerIcon } from '@heroicons/react/24/outline'

import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const free_feature=["Advanced AI","Design & Code Editors","Create Campaign","Campaign Analytics","Segmentation","Ticket Support","Mailatmars Branding"];


const lite_feature=["Advanced AI","All Free features","Unlimited Contacts","Personalization","Dynamic content in email","Report Exporting","3 Steps Drip campaign","Live Support","No Mailatmars Branding"];

const premium_feature=["Advanced AI","Everything In Lite","Batch Campaign","A/B Testing Campaign","20 steps in drip campaign","24*7 whatsapp support"]
const marks={
  10000: '10k',
  100000: '100k',
  200000: '200k',
  300000: '300k',
  400000: '400k',
  500000: '500k',
  600000: '600k',
};

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const [inputValue, setInputValue] = useState(10000);

  const [lite_plan_value,setLiteplan]=useState(8);
  const [premium_plan_value,setPremiumplan]=useState(9);

  const onChange = (val) => {

var val_of_lite_plan=null;
var val_of_premim_plan=null;
var count_of_email=0;
    if(val<10000){


    val_of_premim_plan=9;
    val_of_lite_plan=8;

count_of_email=10000;

  }else if(val<20000){

      val_of_premim_plan=16;
      val_of_lite_plan=14;
      count_of_email=20000;

    }else if(val<40000){

    val_of_premim_plan=30;
    val_of_lite_plan=26;
    count_of_email=40000;
  }else if(val<60000){
    val_of_premim_plan=45;
    val_of_lite_plan=null;
    count_of_email=60000;
  }else if(val<80000){
      val_of_premim_plan=57;
      count_of_email=80000;

    }else if(val<100000){
      val_of_premim_plan=69;
      count_of_email=100000;

    }else if(val<150000){
    val_of_premim_plan=100;
    count_of_email=150000;
  }else if(val<200000){
    val_of_premim_plan=130;
      count_of_email=200000;
  }else if(val<250000){
    val_of_premim_plan=160;
    count_of_email=250000;
  }else if(val<300000){
    val_of_premim_plan=190;
    count_of_email=300000;
  }else if(val<350000){
    val_of_premim_plan=220;
    count_of_email=350000;
  }else if(val<400000){
    val_of_premim_plan=250;
    count_of_email=400000;
  }else if(val<500000){
    val_of_premim_plan=310;
    count_of_email=500000;
  }else if(val<600000){
val_of_premim_plan=null;
count_of_email=600000;
}

setPremiumplan(val_of_premim_plan);
setLiteplan(val_of_lite_plan);
setInputValue(count_of_email);

  };

  return (

<div>
<div class="sm:flex sm:flex-col sm:align-center p-10">
<h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl text-center">
  Pricing
</h1>



  <Row style={{justifyContent: "center",padding:"20px",paddingTop:"40px"}}>

     <Col xl={12} xs={24}>
       <Slider 
       marks={marks}
       min={10000}
       max={600000}
         onChange={onChange}
         value={typeof inputValue === 'number' ? inputValue : 0}
       />
     </Col>

   </Row>


<div
class="mt-12 space-y-3 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6 md:max-w-5xl md:mx-auto xl:grid-cols-3">
<div class="border border-slate-200 rounded-lg shadow-sm divide-y divide-slate-200">
  <div class="p-6">
      <h2 class="text-xl leading-6 font-bold text-slate-900">Free</h2>
      <p class="mt-2 text-base text-slate-700 leading-tight">Try it out! Store up to 2,000 contacts and send up to 6,000 emails/mo.</p>
      <p class="mt-8">
          <span class="text-4xl font-bold text-slate-900 tracking-tighter">$0</span>

          <span class="text-base font-medium text-slate-500">/mo</span>
      </p>

          <a
            href="https://account-v2.mailatmars.com/account/create"
            className="mt-8 text-center rounded-md block w-full bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Get started
          </a>
  </div>
  <div class="pt-6 pb-8 px-6">
      <h3 class="text-sm font-bold text-slate-900 tracking-wide uppercase">What's included</h3>
      <ul role="list" class="mt-4 space-y-3">



      {free_feature.map((item) => (
        <li class="flex space-x-3">
            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 h-5 w-5 text-green-400" width="24"
                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                stroke-linecap="round" stroke-linejoin="round" aria-hidden="true">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
            </svg>
            <span class="text-base text-slate-700">{item}</span>
        </li>

      ))}



      </ul>
  </div>
</div>
<div class="border border-slate-200 rounded-lg shadow-sm divide-y divide-slate-200">
  <div class="p-6">
      <h2 class="text-xl leading-6 font-bold text-slate-900">Lite</h2>
      <p class="mt-2 text-base text-slate-700 leading-tight">Design, send, and measure email marketing campaigns with ease.</p>
      <p class="mt-8">

      {lite_plan_value!=null?

<div>
        <span class="text-4xl font-bold text-slate-900 tracking-tighter">
        ${lite_plan_value}
</span>

        <span class="text-base font-medium text-slate-500">/mo</span><br />
        <span class="text-base font-medium text-slate-500 pt-20 " >For {inputValue} Email sent</span>
        </div>

        :

<span class="text-base font-medium text-slate-500">Go for premium Plan</span>

      }

      </p>
  {lite_plan_value!=null?
      <a
        href={"https://account.mailatmars.com/account/signup/?payment=true&type=lite&emails="+inputValue+"&usd="+lite_plan_value}
        className="mt-8 text-center rounded-md block w-full bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Get started with Lite
      </a>:""}
  </div>
  <div class="pt-6 pb-8 px-6">
      <h3 class="text-sm font-bold text-slate-900 tracking-wide uppercase">What's included</h3>
      <ul role="list" class="mt-4 space-y-3">

      {lite_feature.map((item) => (
        <li class="flex space-x-3">
            <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 h-5 w-5 text-green-400" width="24"
                height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                stroke-linecap="round" stroke-linejoin="round" aria-hidden="true">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l5 5l10 -10"></path>
            </svg>
            <span class="text-base text-slate-700">{item}</span>
        </li>

      ))}

      </ul>
  </div>
</div>
<div class="border border-slate-200 rounded-lg shadow-sm divide-y divide-slate-200">
  <div class="p-6">
      <h2 class="text-xl leading-6 font-bold text-slate-900">Premium</h2>
      <p class="mt-2 text-base text-slate-700 leading-tight">Level up with powerful features to advance your email marketing</p>
      <p class="mt-8">
      {premium_plan_value!=null?

<div>
        <span class="text-4xl font-bold text-slate-900 tracking-tighter">
        ${premium_plan_value}
</span>

        <span class="text-base font-medium text-slate-500">/mo</span>
        <br />
        <span class="text-base font-medium text-slate-500 pt-20 " >For {inputValue} Email sent</span>
        </div>

        :

<span class="text-base font-medium text-slate-500">Contact us</span>

      }
      </p>

      <a
        href={"https://account.mailatmars.com/account/signup/?payment=true&type=lite&emails="+inputValue+"&usd="+premium_plan_value}
        className="mt-8 text-center rounded-md block w-full bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Get started with Premium
      </a>
  </div>
  <div class="pt-6 pb-8 px-6">
      <h3 class="text-sm font-bold text-slate-900 tracking-wide uppercase">What's included</h3>
      <ul role="list" class="mt-4 space-y-3">


            {premium_feature.map((item) => (
              <li class="flex space-x-3">
                  <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 h-5 w-5 text-green-400" width="24"
                      height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"
                      stroke-linecap="round" stroke-linejoin="round" aria-hidden="true">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M5 12l5 5l10 -10"></path>
                  </svg>
                  <span class="text-base text-slate-700">{item}</span>
              </li>

            ))}

      </ul>
  </div>
</div>
</div>
</div>


    </div>
  )
}
