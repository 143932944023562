import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import 'tailwindcss/tailwind.css';

const AdminPage = () => {
    const [leads, setLeads] = useState([]);
    const [password, setPassword] = useState('');
    const [authenticated, setAuthenticated] = useState(false);

    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (password === 'Ravi91068@') {
            setAuthenticated(true);
        } else {
            alert('Incorrect password');
        }
    };

    useEffect(() => {
        if (authenticated) {
            const fetchLeads = async () => {
                try {
                    const response = await fetch('https://account.mailatmars.com/account-v2/leads/get.php');
                    const result = await response.json();
                    if (response.ok) {
                        setLeads(result);
                    } else {
                        message.error('Failed to fetch leads data.');
                    }
                } catch (error) {
                    message.error('An error occurred. Please try again.');
                }
            };

            fetchLeads();
        }
    }, [authenticated]);

    if (!authenticated) {
        return (
            <div className="flex justify-center items-center h-screen">
                <form onSubmit={handlePasswordSubmit} className="bg-white p-6 rounded shadow-md">
                    <h2 className="text-2xl mb-4">Enter Password</h2>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="border p-2 w-full mb-4"
                        placeholder="Password"
                    />
                    <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                        Submit
                    </button>
                </form>
            </div>
        );
    }

    return (
        <div className="admin-page p-5">
            <h1 className="text-2xl font-bold mb-5">Leads Data</h1>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b text-left">Business Email</th>
                            <th className="py-2 px-4 border-b text-left">Full Name</th>
                            <th className="py-2 px-4 border-b text-left">Company Name</th>
                            <th className="py-2 px-4 border-b text-left">Phone Number</th>
                            <th className="py-2 px-4 border-b text-left">Role/Designation</th>
                            <th className="py-2 px-4 border-b text-left">Website URL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leads.map((lead) => (
                            <tr key={lead.id}>
                                <td className="py-2 px-4 border-b">{lead.businessEmail}</td>
                                <td className="py-2 px-4 border-b">{lead.fullName}</td>
                                <td className="py-2 px-4 border-b">{lead.companyName}</td>
                                <td className="py-2 px-4 border-b">{lead.phoneNumber}</td>
                                <td className="py-2 px-4 border-b">{lead.role}</td>
                                <td className="py-2 px-4 border-b">{lead.websiteUrl}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AdminPage;
