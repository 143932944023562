import { Fragment,useState } from 'react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const logo="https://res.cloudinary.com/dttmahifk/image/upload/v1717130110/Untitled_design_3_v14zca.png";



export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (

<div>


    <footer className="bg-white">
  <div className="mx-auto max-w-screen-xl space-y-8 px-4 py-16 sm:px-6 lg:space-y-16 lg:px-8">
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
      <div>
        <div className="text-teal-600">
        <img className="h-10 w-auto" src={logo} />
        </div>

        <p className="mt-4 max-w-xs text-gray-500">
          Empowering your success with seamless, effective email marketing solutions
        </p>
{/*
        <ul className="mt-8 flex gap-6">
          <li>
            <a
              href="https://www.facebook.com/people/Mailatmars/100088641237837/"
              rel="noreferrer"
              target="_blank"
              className="text-gray-700 transition hover:opacity-75"
            >
              <span className="sr-only">Facebook</span>

              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </li>

          <li>
            <a
              href="https://www.linkedin.com/company/mailatmars-marketing-plateform/"
              rel="noreferrer"
              target="_blank"
              className="text-gray-700 transition hover:opacity-75"
            >
              <span className="sr-only">Linkedin</span>

              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 32 32">
  <path d="M 7.5 5 C 6.132813 5 5 6.132813 5 7.5 L 5 24.5 C 5 25.867188 6.132813 27 7.5 27 L 24.5 27 C 25.867188 27 27 25.867188 27 24.5 L 27 7.5 C 27 6.132813 25.867188 5 24.5 5 Z M 7.5 7 L 24.5 7 C 24.785156 7 25 7.214844 25 7.5 L 25 24.5 C 25 24.785156 24.785156 25 24.5 25 L 7.5 25 C 7.214844 25 7 24.785156 7 24.5 L 7 7.5 C 7 7.214844 7.214844 7 7.5 7 Z M 10.4375 8.71875 C 9.488281 8.71875 8.71875 9.488281 8.71875 10.4375 C 8.71875 11.386719 9.488281 12.15625 10.4375 12.15625 C 11.386719 12.15625 12.15625 11.386719 12.15625 10.4375 C 12.15625 9.488281 11.386719 8.71875 10.4375 8.71875 Z M 19.46875 13.28125 C 18.035156 13.28125 17.082031 14.066406 16.6875 14.8125 L 16.625 14.8125 L 16.625 13.5 L 13.8125 13.5 L 13.8125 23 L 16.75 23 L 16.75 18.3125 C 16.75 17.074219 16.996094 15.875 18.53125 15.875 C 20.042969 15.875 20.0625 17.273438 20.0625 18.375 L 20.0625 23 L 23 23 L 23 17.78125 C 23 15.226563 22.457031 13.28125 19.46875 13.28125 Z M 9 13.5 L 9 23 L 11.96875 23 L 11.96875 13.5 Z"></path>
  </svg>
            </a>
          </li>






        </ul>

        */}
      </div>

      <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-5">
    <div>
      <p className="font-medium text-gray-900"><b>Products</b></p>
      <ul className="mt-6 space-y-4 text-sm">
        <li>
          <a href="/products/batch-email-campaign" className="text-gray-700 transition hover:opacity-75"> Batch campaign </a>
        </li>
        <li>
          <a href="/products/email-ab-testing" className="text-gray-700 transition hover:opacity-75"> A/B testing </a>
        </li>
        <li>
          <a href="/products/marketing-automation" className="text-gray-700 transition hover:opacity-75"> Automation </a>
        </li>
        <li>
          <a href="/products/email-editor" className="text-gray-700 transition hover:opacity-75"> Email editor </a>
        </li>
        <li>
          <a href="/products/drip-campaign" className="text-gray-700 transition hover:opacity-75"> Drip campaign </a>
        </li>
        <li>
          <a href="/products/email-campaign-analytics" className="text-gray-700 transition hover:opacity-75"> Analytics </a>
        </li>
      </ul>
    </div>

    <div>
      <p className="font-medium text-gray-900"><b>Solutions</b></p>
      <ul className="mt-6 space-y-4 text-sm">
        <li>
          <a href="/solutions/startup" className="text-gray-700 transition hover:opacity-75"> For Startup </a>
        </li>
        <li>
          <a href="/solutions/blogger" className="text-gray-700 transition hover:opacity-75"> For Blogger </a>
        </li>
        <li>
          <a href="/products/batch-email-campaign" className="text-gray-700 transition hover:opacity-75"> For Marketers </a>
        </li>
        <li>
          <a href="/products/drip-campaign" className="text-gray-700 transition hover:opacity-75"> For Lead generation </a>
        </li>
      </ul>
    </div>

    <div>
      <p className="font-medium text-gray-900"><b>Resource</b></p>
      <ul className="mt-6 space-y-4 text-sm">
      <li>
          <a href="https://mailatmars.com/tools/email-subject-line-suggestion" className="text-gray-700 transition hover:opacity-75"> Subject Line Selector </a>
        </li>
        <li>
          <a href="https://mailatmars.document360.io/v1/en" className="text-gray-700 transition hover:opacity-75"> Knowledge Base </a>
        </li>
        <li>
          <a href="https://blog.mailatmars.com/" className="text-gray-700 transition hover:opacity-75"> Blog </a>
        </li>
        <li>
          <a href="https://mailatmars.document360.io/v1/en" className="text-gray-700 transition hover:opacity-75"> Help center </a>
        </li>
        <li>
          <a href="https://mailatmars1.statuspage.io/" className="text-gray-700 transition hover:opacity-75"> Status </a>
        </li>
      </ul>
    </div>

    <div>
      <p className="font-medium text-gray-900"><b>Company</b></p>
      <ul className="mt-6 space-y-4 text-sm">
        <li>
          <a href="https://dbgkw7y4an7.typeform.com/to/mlVlDqBD" className="text-gray-700 transition hover:opacity-75"> Contact us </a>
        </li>
        <li>
          <a href="/company/terms/" className="text-gray-700 transition hover:opacity-75"> Terms & service </a>
        </li>
        <li>
          <a href="/company/privacy/" className="text-gray-700 transition hover:opacity-75"> Privacy policy </a>
        </li>
        <li>
          <a href="/company/acceptable-use/" className="text-gray-700 transition hover:opacity-75"> Acceptable use policy </a>
        </li>
      </ul>
    </div>

    <div>
      <p className="font-medium text-gray-900"><b>Compare</b></p>
      <ul className="mt-6 space-y-4 text-sm">
        <li>
          <a href="/comparison/mailatmars-vs-mailchimp" className="text-gray-700 transition hover:opacity-75">  Mailchimp </a>
        </li>
        <li>
          <a href="/comparison/mailatmars-vs-brevo" className="text-gray-700 transition hover:opacity-75">  Brevo </a>
        </li>
        <li>
          <a href="/comparison/mailatmars-vs-sendpulse" className="text-gray-700 transition hover:opacity-75">  Sendpulse </a>
        </li>
        <li>
          <a href="/comparison/mailatmars-vs-mailerlite" className="text-gray-700 transition hover:opacity-75">  Mailerlite </a>
        </li>
        <li>
          <a href="/comparison/mailatmars-vs-constantcontact" className="text-gray-700 transition hover:opacity-75">  Constant Contact </a>
        </li>
        <li>
          <a href="/comparison/mailatmars-vs-benchmark-email" className="text-gray-700 transition hover:opacity-75">  Benchmark Email </a>
        </li>
      </ul>
    </div>
  </div>

    </div>

    <p className="text-xs text-gray-500">&copy; 2024. Mailatmars Technologies Limited</p>
  </div>
</footer>

    </div>
  )
}
