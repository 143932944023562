import React, { useState } from 'react';
import { Button, message, Tooltip } from 'antd';
import { BulbOutlined } from '@ant-design/icons';

const endpoint = process.env.REACT_APP_OPENAI_ENDPOINT || 'https://subjectline-previe-text.openai.azure.com';
const apiKey = process.env.REACT_APP_OPENAI_API_KEY || '9G3oHpBRJjTcwaH9kJNRLWoVQzNhoK105ZhRQQtIHXOHCnew0RB0JQQJ99BAACYeBjFXJ3w3AAABACOGTv3C';
const deploymentName = "subject-line-preview";

console.log("Endpoint:", endpoint);
console.log("API Key:", apiKey);

const AISuggestion = ({ subjectLine, onReceiveSuggestion }) => {
  const [aiLoading, setAiLoading] = useState(false);

  const evaluateSubjectLine = async () => {
    if (!subjectLine || subjectLine.trim() === "") {
      message.error('Please enter a subject line Or topic first.');
      return;
    }
    setAiLoading(true);
    try {
      const requestBody = {
        messages: [
          {
            role: "user",
            content: `Evaluate this email subject line for performance: "${subjectLine}". Provide: 1. A score out of 10 for effectiveness. 2. expected email open rate 3. Best related 5 subject line with expected open rate in (expected open rate %) and please give only minimal data for personalization made if required in {{field name}} here field name is firstname,lastname Please provide the response in the following JSON format:
          {
            "score": <number>,
            "open_rate": <number>,
            "suggestions": [{line: <string>, open_rate: <number>}, ...],
          }`
          }
        ],
        max_tokens: 300
      };

      const response = await fetch(
        `${endpoint}/openai/deployments/${deploymentName}/chat/completions?api-version=2024-08-01-preview`,
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "api-key": apiKey
          },
          body: JSON.stringify(requestBody)
        }
      );

      const data = await response.json();

      console.log(data);
      if (response.ok) {
        let suggestion = data.choices[0].message.content;

        suggestion = suggestion.substring(suggestion.indexOf('{'), suggestion.lastIndexOf('}') + 1);
        onReceiveSuggestion(JSON.parse(suggestion));
      } else {
        message.error('Failed to get AI suggestion.');
      }
    } catch (error) {
      console.error("Error:", error.message);
      message.error('An error occurred while getting AI suggestion.');
    } finally {
      setAiLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Get AI Suggestion">
        <Button 
          onClick={evaluateSubjectLine} 
          loading={aiLoading} 
          type="primary"
          icon={<BulbOutlined />}
          style={{
            marginTop: 10,
            height: 62,
            width:72,
            borderRadius: 0,
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
            marginTop:0,background:"#4f46e5"
          }}
        />
      </Tooltip>
    </>
  );
};

export default AISuggestion;
