import React, { useState } from 'react';
import { message, Spin } from 'antd';
import 'tailwindcss/tailwind.css';

import {
    LightBulbIcon,
    PaperAirplaneIcon,
    ChartBarIcon,
    ArrowsRightLeftIcon,
    ScaleIcon,
    UserCircleIcon,
  } from "@heroicons/react/24/outline";
  
  const features = [
    {
      name: "AI-Powered Personalization",
      description: "Automatically tailor email content to resonate with each recipient, increasing engagement and conversions.",
      icon: LightBulbIcon,
    },
    {
      name: "Smart Batch Campaigns",
      description: "Send campaigns in hourly batches to optimize deliverability and avoid inbox overload.",
      icon: PaperAirplaneIcon,
    },
    {
      name: "Advanced Analytics",
      description: "Get real-time insights into campaign performance with detailed metrics and actionable recommendations.",
      icon: ChartBarIcon,
    },
    {
      name: "Easy Automation",
      description: "Streamline workflows and save time with intuitive automation tools that anyone can use.",
      icon: ArrowsRightLeftIcon,
    },
    {
      name: "Scalable Solutions",
      description: "Adapt to your growing business needs with flexible plans and support for high-volume campaigns.",
      icon: ScaleIcon,
    },
    {
      name: "User-Friendly Interface",
      description: "Enjoy a clean and intuitive platform designed to make email marketing simple and accessible for everyone.",
      icon: UserCircleIcon,
    },
  ];
  
  

const LeadsLandingPage = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        businessEmail: '',
        companyName: '',
        phoneNumber: '',
        role: '',
        websiteUrl: ''
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Submitting form data:', formData); // Log form data before submission

        // Validate email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.businessEmail)) {
            message.error('Invalid email format');
            return;
        }

        // Validate URL
        try {
            new URL(formData.websiteUrl);
        } catch (_) {
            message.error('Invalid URL format');
            return;
        }

        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer b74155ec5a0be07ea17edca1c9da4b444d1008c1249d3fab1e");

        const raw = JSON.stringify({
            "firstname": formData.fullName.split(' ')[0],
            "lastname": formData.fullName.split(' ').slice(1).join(' '),
            "email": formData.businessEmail,
            "company_name": formData.companyName,
            "phone": formData.phoneNumber,
            "role": formData.role,
            "website_url": formData.websiteUrl,
            "tag": ["customer", "premium"],
            "status": "subscribe",
            "source": "landing page"
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch("https://api.mailatmars.com/api/add/514290320677f9eeeefad5", requestOptions);
            const result = await response.text();
            console.log(result); // Print the response
            if (response.ok) {
                message.success('Form submitted successfully!');
                window.location.href = 'https://account.Mailatmars.com/account-v2/leads/go.php';
            } else {
                message.error('Failed to submit the form. Please try again.');
            }
        } catch (error) {
            message.error('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="landing-page justify-center items-center h-screen font-lato">
            <div className="p-10 ">
                <a href="https://Mailatmars.com" target="_blank" rel="noopener noreferrer">
                    <img 
                        src="https://res.cloudinary.com/dttmahifk/image/upload/v1717499388/Mailatmars-log-removebg-preview_ef9wn2.png" 
                        alt="Logo" 
                        className="w-24"
                    />
                </a>
            </div>
            <div id="main-lead-content" className="flex  justify-center p-5 flex-col md:flex-row w-full ">
                <div className="w-full md:w-1/2 p-5">
                    <h1 className="text-3xl font-bold mb-5 font-sans">AI-Powered Email Marketing for Marketers, Startups, and Enterprises.</h1>
                    <p className="text-lg mb-5 font-sans">AI-driven personalization boosts engagement, batch campaigns and automation drive better conversions, simplified workflows save time, and the platform scales effortlessly for businesses of all sizes.</p>
                    <p className="text-lg mb-5 font-sans">Built on proven AI technology with a user-friendly design, transparent pricing, dedicated support, and a commitment to delivering measurable growth for your business.</p>
                    <p className="text-lg mb-5 font-sans">Boost your open rates by 30% with our AI and smart sending technology—outperforming the competition.</p>
                    
                </div>
                <div className="w-full md:w-1/2 p-5">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <input
                                type="text"
                                name="fullName"
                                placeholder="Full Name"
                                value={formData.fullName}
                                onChange={handleChange}
                                className="w-full border-b border-gray-500 h-10 focus:outline-none focus:ring-0 font-sans"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <input
                                type="email"
                                name="businessEmail"
                                placeholder="Email"
                                value={formData.businessEmail}
                                onChange={handleChange}
                                className="w-full border-b border-gray-500 h-10 focus:outline-none focus:ring-0 font-sans"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <input
                                type="text"
                                name="companyName"
                                placeholder="Company Name"
                                value={formData.companyName}
                                onChange={handleChange}
                                className="w-full border-b border-gray-500 h-10 focus:outline-none focus:ring-0 font-sans"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <input
                                type="text"
                                name="phoneNumber"
                                placeholder="Phone Number"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                className="w-full border-b border-gray-500 h-10 focus:outline-none focus:ring-0 font-sans"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <input
                                type="text"
                                name="role"
                                placeholder="Role/Designation"
                                value={formData.role}
                                onChange={handleChange}
                                className="w-full border-b border-gray-500 h-10 focus:outline-none focus:ring-0 font-sans"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <input
                                type="url"
                                name="websiteUrl"
                                placeholder="Website URL"
                                value={formData.websiteUrl}
                                onChange={handleChange}
                                className="w-full border-b border-gray-500 h-10 focus:outline-none focus:ring-0 font-sans"
                                required
                            />
                        </div>
                        <div>
                            <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded font-sans" style={{ backgroundColor: '#4f46e5' }}>
                                {loading ? <Spin /> : 'Start Your Free Trial'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div id="content-of-feature" className="mt-10 p-5  rounded-lg ">
               
            <div className="bg-white py-24 sm:py-32 p-5">
  <div className="mx-auto max-w-7xl  lg:px-8">
    <div className="mx-auto max-w-2xl lg:text-center">
      <h2 className="text-base font-semibold text-indigo-600 font-sans">Revolutionize Your Email Marketing</h2>
      <p className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl font-sans">
        Everything You Need to Engage, Convert, and Grow
      </p>
      <p className="mt-6 text-lg text-gray-600 font-sans">
        Mailatmars empowers your email marketing strategy with AI-driven personalization, smart automation, and detailed analytics. 
        Start creating campaigns that deliver real results with ease and efficiency.
      </p>
    </div>
    <div className="mt-12 grid grid-cols-1 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 gap-x-6">
  {features.map((feature, index) => {
    // Define an array of border colors
    const borderColors = [
      "border-red-400",
      "border-blue-400",
      "border-green-400",
      "border-yellow-400",
      "border-purple-400",
      "border-pink-400",
    ];
    // Pick a border color based on the index
    const borderColor = borderColors[index % borderColors.length];

    return (
      <div
        key={feature.name}
        className={`flex items-start border-[2px] ${borderColor} rounded-tl-[20px] rounded-br-[20px] p-6 shadow-sm hover:shadow-md transition-shadow`}
      >
        <div className="flex-shrink-0">
          <div className="flex h-12 w-12 items-center justify-center rounded-lg bg-indigo-600">
            <feature.icon aria-hidden="true" className="h-6 w-6 text-white" />
          </div>
        </div>
        <div className="ml-4">
          <dt className="text-lg font-medium text-gray-900">{feature.name}</dt>
          <dd className="mt-1 text-base text-gray-600">{feature.description}</dd>
        </div>
      </div>
    );
  })}
</div>



  </div>
</div>


            </div>


<div id="lead-testimonial" className=" ">

<div className="bg-gray-900 py-16 px-6 sm:py-20 lg:px-8">
  <div className="mx-auto max-w-7xl">
    <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl text-center font-sans">
      What Our customers Say
    </h2>
    <p className="mt-4 text-lg text-gray-400 text-center font-sans">
      Hear from our happy customers and their experience with Mailatmars.
    </p>
    <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">

    <div className="bg-gray-800 p-8 rounded-lg shadow-md">
        <p className="text-white  text-lg font-sans">
        MailatMars has been a game-changer for us! The affordable pricing and easy-to-use tools make creating email campaigns so much faster, and our engagement rates have never been better.
        </p>
        <div className="mt-6 flex items-center space-x-4">
         
          <div>
            <p className="text-sm font-medium text-white font-sans">Ranagade</p>
            <p className="text-sm text-gray-400 font-sans">Entrepreneur, UK</p>
          </div>
        </div>
      </div>


      
      
      
      
      {/* Testimonial 1 */}
      <div className="bg-gray-800 p-8 rounded-lg shadow-md">
        <p className="text-white  text-lg font-sans">
          Great experience with Mailatmars, got 30% higher open rates and 10% higher click rates compared to previous tools I had used.
        </p>
        <div className="mt-6 flex items-center space-x-4">
         
          <div>
            <p className="text-sm font-medium text-white font-sans">Gaurav</p>
            <p className="text-sm text-gray-400 font-sans">Marketing Head, India</p>
          </div>
        </div>
      </div>

      <div className="bg-gray-800 p-8 rounded-lg shadow-md">
        <p className="text-white  text-lg font-sans">
        Since switching to MailatMars, managing our email marketing has become effortless. The detailed analytics and customizable templates help us deliver more targeted campaigns. It’s been a huge time-saver for our team!
        </p>
        <div className="mt-6 flex items-center space-x-4">
         
          <div>
            <p className="text-sm font-medium text-white font-sans">Joseph Kuperstein</p>
            <p className="text-sm text-gray-400 font-sans">Bussiness owner, USA</p>
          </div>
        </div>
      </div>
      {/* Testimonial 2 */}
      <div className="bg-gray-800 p-8 rounded-lg shadow-md">
        <p className="text-white  text-lg font-sans">
        I have had a great experience with MailatMars since I started using it a few months ago - the customer service is excellent, the features are easy to use, and the platform is reliable and secure.
        </p>
        <div className="mt-6 flex items-center space-x-4">
         
          <div>
            <p className="text-sm font-medium text-white font-sans">Krishnamohan</p>
            <p className="text-sm text-gray-400 font-sans">Sr Marketing Manager, India</p>
          </div>
        </div>
      </div>
      {/* Testimonial 3 */}
      <div className="bg-gray-800 p-8 rounded-lg shadow-md">
        <p className="text-white  text-lg font-sans">
          The graphics and chart-wise view were cool. The file extraction sent, open, and click stats were good—this helped us segregate responses easily.
        </p>
        <div className="mt-6 flex items-center space-x-4">
         
          <div>
            <p className="text-sm font-medium text-white font-sans">Sri Hari Krishnan</p>
            <p className="text-sm text-gray-400 font-sans">Email Marketing Specialist, India</p>
          </div>
        </div>
      </div>
      {/* Testimonial 4 */}
      <div className="bg-gray-800 p-8 rounded-lg shadow-md">
        <p className="text-white  text-lg font-sans ">
          Excellent tool. Value for money. You will get the best results with this tool.
        </p>
        <div className="mt-6 flex items-center space-x-4">
          
          <div>
            <p className="text-sm font-medium text-white font-sans">Sagar</p>
            <p className="text-sm text-gray-400 font-sans">Marketing Manager, India</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
    <footer className="bg-gray-800 py-4">
        <div className="container mx-auto text-center text-white">
            &copy; {new Date().getFullYear()} Mailatmars. All rights reserved.
        </div>
    </footer>
        </div>
    );
};

export default LeadsLandingPage;
